<template>
    <div>
        <h4 class="bold text-uppercase mb-3">
            <span @click="$router.go(-1)" class="font-weight-bold hand">
                <i class="fas fa-chevron-left mx-1"></i>
                {{ $route.name }}
            </span>
        </h4>
        <div class="row mt-3">
            <div class="col-md-8 col-12">
                <div class="card card-gray">
                    <div class="card-body">
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label font-bold">API Key:</label>
                            <div class="col-sm-10">
                                <input
                                    type="text"
                                    placeholder="Nhập API Key của bạn"
                                    class="form-control input-light"
                                    v-model="apiKey"
                                />
                                <button
                                    @click="updateApiKey()"
                                    class="btn mt-4 form-control bold btn-dark-blue"
                                    type="submit"
                                >
                                    Lưu API Key
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="card card-gray-2 mb-3 h-100">
                    <div class="card-body">
                        <div class="">
                            <h6>
                                <i class="fa fa-info mr-1"></i>
                                Bạn cần cập nhật API key sau khi đổi mật khẩu bên Site Gốc.
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { updateAPI } from "../../../api/admin-agency-lv2"
import { catchError } from "../../../helpers"
export default {
    name: "site-price",
    components: {},
    data() {
        return {
            apiKey: ""
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        tools() {
            return this.$store.state.tools.tools
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.agency.site
        },
        reload() {
            return this.$store.state.app.reload
        },
        currency() {
            return this.$store.state.agency.site.currency || "Xu"
        },
        agency() {
            return this.$store.state.agency
        }
    },
    methods: {
        updateApiKey: async function() {
            let postData = { api_key: this.apiKey }
            let data = await updateAPI(postData)
            if (data.status === 200 && data.success) {
                await this.$swal("Thành Công", data.message, "success")
            } else this.$swal("Lỗi", catchError(data), "error")
        }
    }
}
</script>
